/* COLORS */
:root{
    --red: #d8242a;
    --grayText: #a3a3a3;
    --blackText: #1b1919;
    --blackBg: #202020;
    --green: #5aa804;
    --pink: #f72f5e;
}

@media screen and (min-width: 769px){
    .slide-card .single-card-item{background: initial !important;}
    .list-articles .single-formule-article{padding: 0 15px 0 0;}
}

@media screen and (min-width: 769px) and (max-width: 991px){
    .single-card-item .card-item-image{height: 300px;}
    .single-card-item .card-item-image img {width: auto;height: 300px;max-width: inherit;}
}

@media screen and (max-width: 768px){
    .article-composez{padding-bottom: 20px;}
    .order-page .bar-control{position: fixed;bottom: 0;left: 0;right: 0;z-index: 999;}
    .order-page .article-composez .btn.btn-4, .order-page .article-composez .total{width: 50%;padding: 15px 5px !important;font-size: 14px;height: 55px;line-height: 1;display: flex;align-items: center;justify-content: center;}

    .main-slideshow .single-slideshow .bg-slide{height: 100vh;}
    /* .main-slideshow .single-slideshow .bg-slide > img{display: none;} */

    .contact-dailer img{width: 50px;}

    .formule-articles .list-articles{grid-template-columns: auto auto;display: grid;grid-gap: 15px;}
    /* .single-formule-article .article-image .article-price{height: 40px;} */
    .single-formule-article p{font-size: 16px;line-height: 1.3;}

    footer{padding: 60px 0;}
    footer .logo, footer .menu{display: none;}
    footer .social-media{margin-bottom: 25px;}
    .menu{min-height: 200px;}

    nav .container{overflow-y: auto;}

    .main-slideshow{height: 100vh;}
    .main-slideshow .single-slideshow{height: auto;}
    .main-slideshow .single-slideshow img{width: auto;height: 100vh;max-width: initial;}
    .main-slideshow .single-slideshow .bg-slide > img{width: 100%;}
    .main-slideshow .single-slideshow .logo{margin-bottom: 25px;}
    .main-slideshow .single-slideshow .logo img{width: 120px;margin: auto;height: auto;}
    .main-slideshow .single-slideshow .slideshow-content{width: 90%;text-align: center;top: 40%;bottom: initial;left: 50%;transform: translate(-50%, -50%);-moz-transform: translate(-50%, -50%);-webkit-transform: translate(-50%, -50%);}
    .main-slideshow .single-slideshow .slideshow-content h2{font-size: 40px;line-height: 1;letter-spacing: 1px;color: #fff;}
    .main-slideshow .single-slideshow .slideshow-content .button{justify-content: center;}
    .main-slideshow .single-slideshow .slideshow-content .button a{font-size: 18px;letter-spacing: 1px;}

    .slide-card{height: 100vh;}
    .slide-card .container{max-width: 100%;padding: 0;}
    .slide-card .single-card-item{position: relative;height: 100vh;background-position: center;background-repeat: no-repeat;background-size: cover;}
    .slide-card h2{text-align: left;font-size: 45px;margin: 0 0 20px;padding: 0;padding-right: 15px;line-height: 1;color: #fff;text-shadow: 1px 1px #000000;}
    .slide-card h2::after{display: none;}
    .single-card-item .card-item-content{width: 600px;max-width: 90%;right: 0;}
    .single-card-item .card-item-content .wrap{padding: 40px 30px;}
    .single-card-item .card-item-content h4{font-size: 25px;}

    .page-cover{height: 180px;}
    .page-cover .container{padding-bottom: 15px;}
    .page-cover h1{font-size: 25px;line-height: 1.3;}

    .contact-page .card{position: relative;left: 0;min-height: initial;width: 100%;}

    .checkout-card{padding: 0;width: 100%;}
    .checkout-card .wrap{box-shadow: inherit;padding-bottom: 0;}
    .cart-footer{height: 130px;}
    .cart-body{padding: 0;margin: 0;}

    .order-type-popup h3{font-size: 1.4rem;}
    .order-type-popup button{padding: 10px 15px;}

    .list-categories{margin-top: 40px !important;}
    .list-categories .categories-composer{width: 130px;}
    .list-categories .categories-composer, .list-categories .list-composer, .list-categories .list-composer li{height: 55px;font-size: 14px;}
    .list-categories .list-composer li{padding: 0 10px;}
    .list-categories .list-composer{width: calc(100% - 130px);}

    .elements-row .element-row .price{width: auto !important;max-width: 50%;display: flex;align-items: center;justify-content: flex-end;}
    .elements-row .element-row .title{width: 50%;}

    .sub-categories{margin: 15px 0;}

    .wrapper-log{margin: 40px 0;padding: 35px 20px;}

    /* .selection-item-content.active {white-space: nowrap;overflow-x: auto;padding: 0;} */
    .selection-item-content{padding: 0;}
    /* .selection-item-content .attributes-grid{display: inline-flex;column-gap:initial;grid-column-gap:initial} */
    .single-attribute h5{margin: 8px 0 0;font-size: 12px;}
    .single-attribute h5 + small{font-size: 12px;}
    /* .selection-item-content .single-attribute{margin: 0 10px;width: 80px;display: inline-block;}
    .selection-item-content .single-attribute:first-child{margin-left: 0;}
    .selection-item-content .single-attribute:last-child{margin-right: 0;} */

    .contact-page .map-card .map{height: 400px;}

    .order-page{padding-top: 0;}

    .toggle{width: 45px;height: 45px;background-color: transparent;}

    .single-horizontal-article{position: relative;display: block;}
    .single-horizontal-article .article-image {width: 100%;}
    .single-horizontal-article .article-content {width: 100%;position: absolute;bottom: 0;background-color: rgba(255,255,255, .9);padding: 15px 20px;}
    .single-horizontal-article .article-image .article-price{top: 0;right: 0;height: 40px;}

    .selection-item{margin-bottom: 15px;}

    .single-horizontal-article .article-image .add-to-cart{visibility: visible;opacity: 1;width: 50px;height: 50px;}

    .single-horizontal-article.menu-article .article-content{position: relative;}

    .list-categories .list-composer, .order-page .sub-categories{-ms-overflow-style: none;scrollbar-width: none;}
    .list-categories .list-composer::-webkit-scrollbar, .order-page .sub-categories::-webkit-scrollbar{ display: none;}

    .page-cover button{padding: 10px;font-size: 11px;}
    .profile-menu{margin-bottom: 25px;}
}

@media screen and (max-width: 500px){
    .slide-card h2{font-size: 30px;}
    .formule-articles .list-articles{grid-template-columns: auto;}
    .single-formule-article p{font-size: 14px;line-height: 1.3;}
    .single-card-item .card-item-content h4{font-size: 20px;}

    .single-horizontal-article{align-items: flex-start;}
    /* .single-horizontal-article .article-image{width: 120px;}
    .single-horizontal-article .article-content{width: calc(100% - 120px);padding: 8px 8px 8px 15px;}
    .single-horizontal-article .article-image .article-price{height: 40px;} */
    .single-horizontal-article .article-content h4{margin: 0 0 5px;font-size: 17px;font-weight: 500;color: #000;}
    .single-horizontal-article .article-content p{font-size: 12px;overflow: hidden;line-height: 1.4;}
    .poopup-animated{padding: 30px 15px;}
    .poopup-animated .poopup-content{padding: 30px 20px 35px;max-height: calc(100vh - 100px);overflow-y: auto;}
    .kep-login-facebook{width: 100%;padding: calc(.34435vw + 13.38843px) 0;}
    .contact-page .card .contact-tel p, .contact-page .card .contact-tel p a{font-size: 18px;}
    .contact-dailer{right: 15px;bottom: 15px;}
    .contact-page .map-card .map{height: 300px;}

    nav{top: 0;right: -310px;width: 300px;height: 100vh;}
    .attributes-grid{grid-template-columns: repeat(3, 1fr);}

    .header-menu{position: -webkit-sticky;position: sticky;top: 0;z-index: 20;}
    .header-menu .toggle{justify-content: flex-start;}

    .single-horizontal-article.menu-article .article-content{width: calc(100% - 60px);}
    .single-horizontal-article.menu-article .article-price{display: block;width: 60px;text-align: right;}
    .single-horizontal-article.menu-article .article-price span{padding: 0;margin-bottom: 2px;margin-bottom: 2px;display: block;}
}