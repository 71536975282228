/* FONT */


/* COLORS */
:root{
    --red: #d8242a;
    --grayText: #a3a3a3;
    --blackText: #1b1919;
    --blackBg: #202020;
    --green: #000; /*#5aa804;*/
    --greenTransparent: rgba(90, 168, 4, .7);
    --pink: #f72f5e;
}


body{overflow-x: hidden;font-size: 14px;color: var(--grayText);}

a, a:hover, a:active, a:focus{text-decoration: none;}
a, button, .btn, .gallery .single-gallery img, nav, .cart-mobile{transition: all .3s;-moz-transition: all .3s;-webkit-transition: all .3s;}
img{max-width: 100%;}
.form-control:focus {
    border-color: var(--red);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(216, 36, 42, 0.6);}


Header{position: absolute;top: 10px;left: 0;right: 0;z-index: 999;}
header .row{display: flex;align-items: center;justify-content: space-between;}
header .wrap{width: calc(100% - 150px);height: 70px;background-color: rgba(0,0,0, .5);position: relative;}
header .wrap::before{content: "";height: 70px;background-color: rgba(0,0,0, .5);display: block;width: 30px;position: absolute;top: 0;left: -30px;z-index: 1;}
header .wrap-container{width: 100%;display: flex;align-items: center;justify-content: space-between;height: 100%;}
header .logo{width: 150px;z-index: 9;position: relative;}

.button{margin-right: 25px;display: flex;align-items: center;justify-content: center;}
.button a{color: #fff;background-color: var(--red);padding: 3px 18px 5px;font-size: 18px;border-radius: 25px;}
.button a:hover{background-color: #fff;color: var(--red);}

.toggle{width: 70px;height: 70px;display: flex;align-items: center;justify-content: center;cursor: pointer;background-color: var(--blackText);}
.toggle img{width: 25px;}

.logo{overflow: hidden;}
.logo img{width: 150px;height: 150px;}

.menu{overflow: hidden;}
.menu a{color: #fff;margin: 0 15px;font-size: 14px;text-transform: uppercase;}
.menu a:hover{color: var(--grayText);}

.social-media{overflow: hidden;}
.social-media p{font-size: 16px;color: #fff;margin-bottom: 10px;}
.social-media img{margin: 0 10px;width: 40px;}

/* - */
footer{background-color: var(--blackBg);padding: 30px 0 20px;overflow: hidden;margin-top: -5px;position: relative;}
footer p{color: var(--grayText);margin: 0;font-size: 12px;}
footer .logo, footer .menu{margin-bottom: 20px;}
footer .social-media{margin-bottom: 35px;}

/* - */
.homepage-slideshow{overflow: hidden;position: relative;}
.main-slideshow{overflow: hidden;}
.main-slideshow .single-slideshow{position: relative;overflow: hidden;}
.main-slideshow .single-slideshow .bg-slide{background-repeat: no-repeat;background-position: center;background-size: cover;width: 100%;height: 100%;}
.main-slideshow .single-slideshow img{width: 100%;}
.main-slideshow .single-slideshow .slideshow-content{width: 500px;position: absolute;bottom: 80px;text-align: left;}
.main-slideshow .single-slideshow .slideshow-content h2{color: var(--blackText);font-size: 40px;line-height: 40px;letter-spacing: 2.5px;margin: 0 0 20px;font-weight: 300;}
.main-slideshow .single-slideshow .slideshow-content .button{margin: 0;justify-content: start;}
.main-slideshow .single-slideshow .slideshow-content .button a{text-align: center;text-transform: uppercase;font-size: 16px;letter-spacing: 2px;padding: 10px 15px;border-radius: 35px;}
.main-slideshow .slick-dots{top: 50%;right: 20px;left: initial;width: auto;height: auto;bottom: initial;transform: translateY(-50%);-moz-transform: translateY(-50%);-webkit-transform: translateY(-50%);}
.main-slideshow .slick-dots li{width: 12px;height: 12px;display: block;margin-bottom: 7px;}
.main-slideshow .slick-dots li:last-child{margin-bottom: 0;}
.main-slideshow .slick-dots li button{width: 12px;height: 12px;background: #fff;border-radius: 50%;padding: 0;}
.main-slideshow .slick-dots li.slick-active button{background-color: var(--red);}
.main-slideshow .slick-dots li button::before{display: none;}

/* - */
.order-page{padding-top: 130px;}
.slide-go-order-page{position: absolute;display: flex;flex-direction: column;bottom: 35px;left: 50%;transform: translateX(-50%);-moz-transform: translateX(-50%);-webkit-transform: translateX(-50%);}
.slide-go-order-page a{text-align: center;text-transform: uppercase;font-size: 18px;letter-spacing: 1px;padding: 14px 20px;border-radius: 35px;background-color: var(--red);color: #fff;display: block;width: 280px;font-weight: 300;}
.slide-go-order-page img{height: 50px;margin-top: 20px;-webkit-animation: action 1s infinite alternate;animation: action 1s infinite alternate;}
@-webkit-keyframes action {0% { transform: translateY(0); }100% { transform: translateY(-10px); }}
@keyframes action {0% { transform: translateY(0); }100% { transform: translateY(-10px); }}
.order-page .article-composez .btn.btn-4{width: 220px;border-radius: 0;border: 0;}
.order-page .article-composez .total{padding-left: 40px !important;position: relative;background-color: #ddd;color: var(--blackBg);font-size: 16px;line-height: 1.15;width: calc(100% - 220px);height: 56px;border-radius: 0;}
.order-page .article-composez .total span{font-weight: bold;font-size: 20px;}

/* - */
.instagram-gallery{display: flex;overflow: hidden;position: relative;flex-wrap: wrap;}
.instagram-gallery .account-link{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);-moz-transform: translate(-50%, -50%);-webkit-transform: translate(-50%, -50%);}
.instagram-gallery .account-link img{width: 100px;height: 100px;}
.instagram-gallery .single-instagram{width: 16.667%;}
.instagram-gallery .single-instagram img{width: 100%;}

/* - */
/* .gallery{margin: 60px 0;overflow-x: auto;width: 100%;white-space: nowrap;}
.gallery .single-gallery{width: 40%;display: inline-block;} */
.gallery{margin: 60px 0 0;}
.gallery .single-gallery{z-index: 9;}
.gallery .single-gallery img{width: 100%;}
.gallery .single-gallery:hover{z-index: 9999;position: relative;}
.gallery .single-gallery:hover img{transform: scale(1.3);}

/* - */
.formule-articles{margin: 60px 0 0;overflow: hidden;padding-top: 20px;}
.formule-articles .list-articles .slick-dots{top: -30px;bottom: initial;right: 0;left: initial;width: auto;}
.formule-articles .list-articles .slick-dots li{width: 8px;height: 8px;display: inline-block;margin-right: 5px;}
.formule-articles .list-articles .slick-dots li:last-child{margin-right: 0;}
.formule-articles .list-articles .slick-dots li button{width: 8px;height: 8px;background: var(--grayText);border-radius: 50%;padding: 0;}
.formule-articles .list-articles .slick-dots li.slick-active button{background-color: var(--red);}
.formule-articles .list-articles .slick-dots li button::before{display: none;}
.formule-articles .formule-list{overflow: hidden;}
.formule-articles .formule-list h3{text-transform: uppercase;font-weight: bold;color: var(--blackText);margin: 0 0 10px;}
.formule-articles .formule-list ul{list-style-type: none;padding: 0;margin: 0;}
.formule-articles .formule-list ul li{font-size: 20px;font-weight: 300;position: relative;padding-left: 20px;color: var(--gray);cursor: pointer;}
.formule-articles .formule-list ul li::before{content: "";width: 12px;height: 12px;background: url('./img/arrow-right.svg') no-repeat center;background-size: contain;position: absolute;left: 0;top: 50%;transform: translateY(-50%);-moz-transform: translateY(-50%);-webkit-transform: translateY(-50%);}
.formule-articles .formule-list ul li:hover{color: var(--red);}

/* - */
.fullwidth-background{background-color: #fff url('./img/checked.svg') no-repeat center;background-size: cover;overflow: hidden;width: 100%;}

/* - */
.single-formule-article{margin-bottom: 20px;}
.single-formule-article .article-image{width: 100%;position: relative;}
.single-formule-article .article-image .add-to-cart{visibility: hidden;opacity: 0;width: 100%;position: absolute;top: 0;bottom: 0;left: 0;right: 0;background-color: var(--greenTransparent);display: flex;align-items: center;justify-content: center;}
.single-formule-article .article-image .add-to-cart img{width: 50%;cursor: pointer;}
.single-formule-article .article-image img{width: 100%;}
.single-formule-article:hover .article-image .add-to-cart{visibility: visible;opacity: 1;}
.single-formule-article .article-image .article-price{position: absolute;bottom: -5px;right: -5px;z-index: 10;background-color: var(--red);height: 50px;color: #fff;font-size: 14px;padding: 10px 7px;width: 70px;text-align: center;}
.single-formule-article h4{margin: 0 0 5px;font-size: 20px;}
.single-formule-article h4 a{color: var(--blackText);}
.single-formule-article h4 a:hover{color: var(--red);}
.single-formule-article p{font-weight: 100;color: var(--grayText);font-size: 18px;font-style: italic;margin: 0;}

/* - */
.single-horizontal-article{display: flex;background-color: #efefef;}
.single-horizontal-article .article-image{width: 250px;position: relative;}
.single-horizontal-article .article-image .add-to-cart{visibility: hidden;opacity: 0;width: 100%;position: absolute;top: 0;bottom: 0;left: 0;right: 0;background-color: var(--greenTransparent);display: flex;align-items: center;justify-content: center;}
.single-horizontal-article .article-image .add-to-cart img{width: 50%;cursor: pointer;}
.single-horizontal-article .article-image img{width: 100%;cursor: pointer;}
.single-horizontal-article:hover .article-image .add-to-cart{visibility: visible;opacity: 1;}
.single-horizontal-article .article-image .article-price{position: absolute;bottom: -5px;right: -5px;z-index: 10;background-color: var(--red);height: 55px;color: #fff;font-size: 14px;padding: 10px 7px;width: 70px;text-align: center;}
.single-horizontal-article .article-content{width: calc(100% - 250px);padding: 25px;text-align: left;}
.single-horizontal-article .article-content h4{font-weight: 200;margin: 0 0 15px;font-size: 30px;color: var(--gray);background: transparent;}
.single-horizontal-article .article-content p{font-weight: 300;color: var(--gray);font-size: 13px;margin: 0;}

.list-articles{text-align: center;}
.single-horizontal-article.menu-article{position: relative;padding: 10px 0;display: flex;align-items: center;border-bottom: 1px solid #ccc;background-color: transparent;text-align: left;}
.single-horizontal-article.menu-article .article-content{width: calc(100% - 110px);padding: 0;}
.single-horizontal-article.menu-article .article-content h4{font-size: 20px;font-weight: normal;margin: 0 0 2px;}
.single-horizontal-article.menu-article .article-content p{font-size: 12px;}
.single-horizontal-article.menu-article .article-price{overflow: hidden;width: 110px;display: flex;}
.single-horizontal-article.menu-article .article-price span{width: 60px;text-align: right;color: black;font-weight: bold;padding-top: 5px;}
.single-horizontal-article.menu-article .article-price .addto-article{position: relative;}
.single-horizontal-article.menu-article .article-price .addto-article img{cursor: pointer;width: 30px;height: 30px;margin-left: 10px;}
.single-horizontal-article.menu-article ..animation-cart{width: 100%;height: 100%;top: 0;right: 0;}

/* - */
.page-cover{background-position: center;background-repeat: no-repeat;background-size: cover;overflow: hidden;height: 400px;}
.page-cover .container{display: flex;align-items: flex-end;justify-content: space-between;height: 100%;padding-bottom: 60px;}
.page-cover h1{color: var(--blackText);font-size: 50px;line-height: 60px;letter-spacing: 2.5px;margin: 0;font-weight: 300;}
.page-cover button{background-color: var(--red);color: #fff;border: 0;padding: 10px 25px;border-radius: 25px;text-transform: uppercase;}

/* - */
.card{background-color: var(--blackText);border: 0;padding: 50px;}

/* - */
.contact-page{overflow: hidden;}
.contact-page .map-card{overflow: hidden;}
.contact-page .map-card h2{font-weight: 300;font-size: 60px;margin: 0 0 20px;color: var(--blackText);padding-left: 2em;}
.contact-page .map-card .map{height: 500px;background-color: #f5f5f5;position: relative;}
.contact-page .card{color: #fff;text-align: left;position: absolute;left: 15px;top: 0;z-index: 9;min-height: 450px;}
.contact-page .card h3{margin: 0 0 30px;font-size: 20px;text-transform: uppercase;}
.contact-page .card h4{margin: 0 0 5px;font-size: 16px;}
.contact-page .card p, .contact-page .card p a{font-size: 14px;font-weight: 300;color: var(--grayText);max-width: 180px;margin: 0;}
.contact-page .card .contact-email{margin: 25px 0;}
.contact-page .card .contact-tel p, .contact-page .card .contact-tel p a{font-size: 25px;max-width: initial;font-weight: 500;letter-spacing: 2px;color: #fff;}

/* - */
.list-categories{background-color: #f0f0f0;display: flex;align-items: center;justify-content: space-between;overflow: hidden;}
.list-categories .categories-composer{color: #fff;width: 250px;background-color: var(--grayText);height: 70px;display: flex;align-items: center;justify-content: center;text-transform: uppercase;font-size: 16px;cursor: pointer;letter-spacing: 1px;}
.list-categories .list-composer{width: calc(100% - 250px);white-space: nowrap;overflow-x: auto;height: 70px;overflow-y: hidden;}
.list-categories .list-composer ul{padding: 0;list-style-type: none;margin: 0;}
.list-categories .list-composer li{cursor: pointer;position: relative;height: 70px;display: inline-flex;padding: 0 20px;align-items: center;justify-content: center;font-size: 16px;color: var(--blackText);}
.list-categories .list-composer li.active, .list-categories .list-composer li:hover{color: #fff;background-color: var(--red);}
/* .list-categories .list-composer li.active::after, .list-categories .list-composer li:hover::after{content: "";background-color: var(--red);height: 6px;position: absolute;bottom: 0;left: 0;right: 0;} */
.sub-categories{margin: 25px 0;white-space: nowrap;overflow-x: auto;}
.sub-categories ul{padding: 0;list-style-type: none;margin: 0;}
.sub-categories li{color: var(--blackText);font-size: 20px;display: inline-block;margin-right: 35px;cursor: pointer;padding-bottom: 7px;border-bottom: 3px solid transparent;}
.sub-categories li:last-child{margin: 0;}
.sub-categories li.active, .sub-categories li:hover{color: var(--red);border-color: var(--red);}

/* - */
.sign-status{display: flex;align-items: center;}
.sign-status a{text-transform: uppercase;color: var(--grayText);font-weight: 500;font-size: 14px;margin-left: 10px;}
.sign-status a:hover{color: var(--blackText);}
.sign-status button{text-transform: uppercase;color: var(--grayText);font-weight: 500;font-size: 14px;margin-left: 10px;background-color: transparent;border: 0;text-align: left;padding: 0;}
.sign-status img{width: 45px;height: 45px;}
.form-group a{color: #000;}
.facebook-login .kep-login-facebook{margin-bottom: 20px;}
.facebook-login .kep-login-facebook, .google-login button{width: 100%;padding: 0;border-radius: 40px !important;height: 60px;line-height: 60px;}
.google-login button{justify-content: center;font-size: calc(.27548vw + 12.71074px) !important;text-transform: uppercase;}
.google-login button div{height: 40px;padding: 0 2px !important;line-height: 36px;}
.google-login button span{font-weight: bold !important;}


/* - */
.cart-data{overflow: hidden;}
.cart-header{padding: 0 25px 15px;margin-bottom: 15px;border-bottom: 1px solid #eee;display: flex;justify-content: space-between;align-items: center;}
.cart-description{width: calc(100% - 80px);}
.cart-description h4{margin: 0 0 5px;color: var(--blackText);text-transform: uppercase;font-size: 18px;}
.cart-description p{margin: 0;font-size: 10px;}
.cart-counter{width: 50px;position: relative;padding-top: 7px;}
.cart-counter img{width: 35px;height: 40px;}
.cart-counter span{width: 25px;height: 25px;font-size: 11px;background-color: var(--red);display: flex;position: absolute;top: 0;right: 0;align-items: center;justify-content: center;border-radius: 50%;color: #fff;}

.cart-body{padding-bottom: 15px;margin-bottom: 15px;}
.elements-row{overflow: hidden;}
.elements-row .element-row{display: flex;align-items: flex-start;justify-content: space-between;margin: 0 0 15px;position: relative;padding: 5px 25px 0;min-height: 32px;}
.elements-row .element-row .qty{width: 25px;color: var(--blackText);font-size: 13px;}
.elements-row .element-row .title{width: calc(100% - 85px);color: var(--grayText);font-size: 11px;}
.elements-row .element-row .title span{color: var(--blackText);display: block;font-size: 13px;text-transform: uppercase;letter-spacing: .5px;}
.elements-row .element-row .price{width: 60px;text-align: right;color: var(--blackText);text-transform: uppercase;font-size: 13px;letter-spacing: -1px;}
.elements-row .element-row .update-qty{opacity: 0;visibility: hidden;position: absolute;left: 5px;top: 0;height: 100%;}
.elements-row .element-row .update-qty span{cursor: pointer;display: block;width: 15px;color: #fff;background-color: var(--red);height: 15px;line-height: 14px;text-align: center;margin-bottom: 2px;font-size: 15px;}
.elements-row .element-row .update-qty span:first-child{border-radius: 10px 10px 0 0;}
.elements-row .element-row .update-qty span:last-child{border-radius: 0 0 10px 10px;}
.elements-row .element-row .title i{display: block;}
.elements-row .element-row:hover .update-qty{opacity: 1;visibility: visible;}

.cart-comment{overflow: hidden;margin-bottom: 15px;padding: 0 25px;}
.cart-comment span{width: 100%;border: 0;background-color: #f7f7f7;padding: 8px 10px;font-size: 12px;display: block;color: var(--blackText);cursor: pointer;}

.cart-delivery{padding: 0 25px 15px;padding-bottom: 15px;font-size: 13px;}
.cart-delivery div{display: flex;justify-content: space-between;}
.cart-delivery .total{color: var(--blackText);font-weight: 500;}

.cart-footer{padding: 15px 25px;height: 200px;border-top: 1px solid #eee;}
.cart-footer button{width: 100%;height: 55px;color: #fff;border: 0;text-transform: uppercase;font-size: 18px;letter-spacing: 1px;padding: 0 25px;text-align: left;background: var(--red) url('./img/arrow-white-right.svg') no-repeat calc(100% - 15px) center;background-size: 15px;}
.cart-footer button:hover{border-radius: 25px;}

.checkout-card .wrap{padding: 20px 0;box-shadow: 0 0 15px 2px #dfdfdf;position: sticky;position: -webkit-sticky;top: 0;}
.checkout-card .sign-status{padding: 0 25px 15px;margin-bottom: 15px;border-bottom: 1px solid #eee;}

/* - */
.cart-coupon{display: flex;align-items: center;color: var(--blackText);font-size: 12px;cursor: pointer;padding: 0 25px 15px;margin-bottom: 15px;border-bottom: 1px solid #eee;}
.cart-coupon img{width: 23px;height: 19px;margin-right: 5px;}
.form-coupon{width: 100%;background-color: #fff;display: flex;align-items: center;justify-content: space-between;position: relative;}
.form-coupon input{padding: 8px 8px 8px 30px;width: calc(100% - 80px);border: 1px solid #dfdfdf;height: 35px;}
.form-coupon button{width: 80px;height: 35px;background-color: var(--blackBg);color: #fff;border: 0;text-align: center;}
.form-coupon .close-coupon{position: absolute;left: 8px;cursor: pointer;}
.form-coupon .close-coupon img{width: 13px;height: 13px;margin-right: 0;}

/* - */
.selection-item{margin-bottom: 30px;}
.selection-item-title{padding: 8px 15px;cursor: pointer;display: flex;align-items: center;justify-content: space-between;background-color: #f9f9f9;}
.selection-item-title h4{text-transform: uppercase;letter-spacing: 2px;margin: 0;font-size: 22px;line-height: 1;color: #000;font-weight: 500;}
.selection-item-title p{font-style: italic;font-weight: 300;margin: 0;}
.selection-item-title > p{font-weight: bold;color: var(--red);}
.selection-item-content{padding: 0 25px;overflow: hidden;display: none;}
.selection-item-content.active{display: block;}
.selection-item-content .single-attribute{margin: 30px 0 0;}
.selection-item-content .single-attribute{margin: 30px 0 0;}
.selection-item-content .single-attribute .option-image{position: relative;}
.selection-item-content .single-attribute .option-qty{display: flex;align-items: center;justify-content: center;border-radius: 50%;background-color: var(--greenTransparent);position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 9;}
.selection-item-content .single-attribute .option-qty .wrap{display: flex;align-items: center;color: #fff;font-size: 16px;}
.selection-item-content .single-attribute .option-qty .wrap i{background-color: var(--red);width: 20px;height: 20px;line-height: 17px;display: block;color: #fff;font-style: normal;border-radius: 50%;font-size: 20px;cursor: pointer;}
.selection-item-content .single-attribute.blur-it{position: relative;}
.selection-item-content .single-attribute.blur-it > *{opacity: .3;}
.selection-item-content .single-attribute.blur-it .no-exit{opacity: 1;position: absolute;top: 30px;z-index: 99;color: red;font-weight: bold;left: 0;right: 0;padding: 0 5px;text-align: center;}

/* - */
.attributes-grid{display: grid;grid-template-columns: repeat(5, 1fr);column-gap: 25px;}

/* - */
.single-attribute{position: relative;text-align: center;margin: 0 0 25px;}
.single-attribute img{width: 100%;border-radius: 50%;}
.single-attribute span{position: absolute;top: 0;right: 0;z-index: 5;}
.single-attribute span img{width: 20px;height: 20px;}
.single-attribute h5{margin: 10px 0 0;color: var(--grayText);font-weight: 400;font-size: 16px;}

/* - */
.slide-card{overflow: hidden;}
.slide-card h2{text-align: center;font-size: 45px;color: var(--blackText);max-width: 700px;margin: 0 auto 35px;padding: 0 0 35px;position: relative;}
.slide-card h2::after{content: "";background: url('./img/arrow-down.svg') no-repeat center;background-size: contain;width: 20px;height: 40px;position: absolute;bottom: -17.5px;left: 50%;transform: translateX(-50%);-moz-transform: translateX(-50%);-webkit-transform: translateX(-50%);z-index: 1;}

.single-card-item{position: relative;}
.single-card-item .card-item-image{width: calc(100% - 300px);overflow: hidden;}
.single-card-item .card-item-image img{width: 100%;}
.single-card-item .card-item-content{position: absolute;top: 50%;right: 7px;width: 450px;max-width: 100%;transform: translateY(-50%);-moz-transform: translateY(-50%);-webkit-transform: translateY(-50%);}
.single-card-item .card-item-content .wrap{overflow: hidden;padding: 40px 40px 25px;background-color: #fff;width: 100%;box-shadow: 0 0 15px 1px rgba(0,0,0, .1);}
.single-card-item .card-item-content h4{color: var(--blackText);margin: 0 0 15px;font-size: 20px;font-weight: 400;}
.single-card-item .card-item-content p{line-height: 22px;font-size: 14px;margin: 0 0 15px;}
.single-card-item .card-item-content a{position: relative;text-transform: uppercase;font-size: 12px;color: var(--red);font-weight: 500;display: flex;align-items: center;}
.single-card-item .card-item-content a::after{content: "";background: url('./img/arrow-right-red.svg') no-repeat center;background-size: contain;height: 9px;width: 20px;display: block;margin-left: 6px;}
.slide-card-wrapper .slick-dots{bottom: 5px;right: 0;width: auto;}
.slide-card-wrapper .slick-dots li{width: 7px;height: 7px;display: inline-block;margin-right: 5px;}
.slide-card-wrapper .slick-dots li:last-child{margin-right: 0;}
.slide-card-wrapper .slick-dots li button{width: 7px;height: 7px;background: var(--grayText);border-radius: 50%;padding: 0;}
.slide-card-wrapper .slick-dots li.slick-active button{background-color: var(--red);}
.slide-card-wrapper .slick-dots li button::before{display: none;}

/* - */
.poopup-animated{position: fixed;top: 0;bottom: 0;right: 0;left: 0;padding: 30px;z-index: 9999;display: flex;align-items: center;justify-content: center;overflow-y: auto;}
.poopup-animated .poopup-close{position: absolute;top: 0;bottom: 0;right: 0;left: 0;background-color: rgba(0,0,0,.3);}
.poopup-animated .poopup-title{border-bottom: 2px solid var(--red);padding: 10px 0;font-size: 25px;text-transform: uppercase;color: var(--red);letter-spacing: 1px;}
.poopup-animated .poopup-content{padding: 50px;max-width: 100%;width: 500px;background: #fff;position: relative;z-index: 9;}
.poopup-animated .poopup-content form{margin: 0;margin: 0;box-shadow: none;padding: 0;}
.changeform{margin: 25px 0 0;font-style: italic;font-size: 13px;color: var(--blackText);cursor: pointer;text-align: center;}
.changeform a{font-size: 13px;color: var(--blackText);cursor: pointer;}
.changeform:hover, .changeform a:hover{text-decoration: underline;}
.poopup-close-icon{position: absolute;top: 15px;width: 25px;left: 15px;z-index: 99;cursor: pointer;}
.poopup-animated.large-popup .poopup-content{width: 800px;}

/* - */
.wrapper-log{margin: 100px 0;box-shadow: 0 0 10px 1px rgba(0,0,0, .1);padding: 50px;}
/* .form-sign{overflow: hidden;} */
.form-sign label{font-weight: bold;font-size: 16px;}
.form-sign input{border: 1px solid #dfdfdf;border-radius: 0;height: 50px;}
.form-sign .form-control + div{color: red;margin-top: 3px;}
.form-sign button{background-color: var(--red);min-width: 200px;max-width: 280px;border: 2px solid var(--red) !important;color: #fff;text-transform: uppercase;letter-spacing: 1px;padding: 10px;width: 50%;display: block;margin: auto;}
.form-sign button:hover{background-color: transparent;color: var(--red);}
.form-sign .small-loading{width: 30px;display: block;margin: auto;}

.skeleton img{width: 100%;opacity: .4;}

.input-error{background-color: #F8D7DA;border-color: red;}

.LoaderBalls {width: 70px;margin: auto;display: flex;justify-content: space-between;align-items: center;}
.LoaderBalls__item {width: 15px;height: 15px;border-radius: 50%;background: var(--red);}
.LoaderBalls__item:nth-child(1) {animation: bouncing 0.4s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95);}
.LoaderBalls__item:nth-child(2) {animation: bouncing 0.4s 0.1s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;}
.LoaderBalls__item:nth-child(3) {animation: bouncing 0.4s 0.2s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;}
@keyframes bouncing {
    0% {transform: translate3d(0, 10px, 0) scale(1.2, 0.85);-moz-transform: translate3d(0, 10px, 0) scale(1.2, 0.85);-webkit-transform: translate3d(0, 10px, 0) scale(1.2, 0.85);}
    100% {transform: translate3d(0, -10px, 0) scale(0.9, 1.1);-moz-transform: translate3d(0, -10px, 0) scale(0.9, 1.1);-webkit-transform: translate3d(0, -10px, 0) scale(0.9, 1.1);}
}
@-webkit-keyframes bouncing {
    0% {transform: translate3d(0, 10px, 0) scale(1.2, 0.85);-moz-transform: translate3d(0, 10px, 0) scale(1.2, 0.85);-webkit-transform: translate3d(0, 10px, 0) scale(1.2, 0.85);}
    100% {transform: translate3d(0, -10px, 0) scale(0.9, 1.1);-moz-transform: translate3d(0, -10px, 0) scale(0.9, 1.1);-webkit-transform: translate3d(0, -10px, 0) scale(0.9, 1.1);}
}

button:disabled, a:disabled{background-color: #ccc !important;;border: 0 !important;}

/* - */
.profile-menu{overflow: hidden;}
.profile-menu ul{padding: 0;margin: 0;list-style-type: none;box-shadow: 0 0 10 1px rgba(0,0,0, .2);}
.profile-menu ul li{position: relative;border-bottom: 1px solid #dfdfdf;}
.profile-menu ul li:last-child{border-bottom: 0;}
.profile-menu ul li a{border-left: 3px solid transparent;padding: 12px 15px;display: flex;align-items: center;color: var(--blackText);text-transform: capitalize;font-size: 16px;font-weight: normal;}
.profile-menu ul li img{width: 30px;margin-right: 7px;}
.profile-menu ul li a:hover{color: var(--red);border-left-color: var(--red);}

/* - */
.cartLoading{position: absolute;top: 0;left: 0;height: 100%;background-color: #fff;width: 100%;display: flex;align-items: center;justify-content: center;}

/* - */
.loading-fullpage{position: fixed;top: 0;right: 0;left: 0;bottom: 0;display: flex;align-items: center;justify-content: center;background-color: #fff;z-index: 99;}

/* - */
.btn.btn-4{color: #fff;cursor: pointer;font-size: 16px;font-weight: 400;position: relative;}
.btn-4{border: 1px solid;overflow: hidden;position: relative;background-color: var(--red);}
.btn-4 span{z-index: 20;}
.btn-4::after{background: #fff;content: "";height: 155px;left: -90px;opacity: 0.2;position: absolute;top: -30px;transform: rotate(35deg);width: 50px;transition: all .3 cubic-bezier(0.19, 1, 0.22, 1);-moz-transition: all .3 cubic-bezier(0.19, 1, 0.22, 1);-webkit-transition: all .3 cubic-bezier(0.19, 1, 0.22, 1);z-index: 1;}
.btn-4:hover::after{left: calc(100% + 90px);transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);}

/* - */
.success-page{padding-top: 250px;padding-bottom: 100px;text-align: center;}
.success-page h1{margin: 15px 0 30px;color: var(--blackBg);}
.success-page > a{color: #fff;cursor: pointer;font-size: 16px;font-weight: 400;position: relative;padding: 15px 30px;text-transform: uppercase;border: 1px solid;overflow: hidden;position: relative;background-color: var(--red);}
.success-page > a:hover{border-radius: 25px;}

/* - */
.row-order{display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #dfdfdf;padding: 10px 15px;color: var(--blackBg);}
.row-order .order-id{font-weight: bold;width: 100px;font-size: 18px;}
.row-order .order-items{width: calc(100% - 450px);padding: 0 15px;}
.row-order .order-total{width: 100px;font-weight: bold;}
.row-order .order-status{width: 150px;text-align: center;}
.row-order .order-check{width: 75px;text-align: right;}
.row-order .order-check img{width: 25px;}

.single-order h5{text-transform: uppercase;color: var(--red);font-size: 16px;margin-bottom: 15px;}
.order-item{display: flex;justify-content: space-between;overflow: hidden;margin: 25px 0;padding-bottom: 25px;border-bottom: 1px solid #eee;}
.order-item:last-child{margin: 0;padding: 0;border: 0;}
.order-item .order-image{width: 200px;}
.order-item .order-image img{width: 100%;}
.order-item .order-description{width: calc(100% - 200px);padding-left: 20px;}
.order-item .order-description h3{color: var(--blackBg);margin: 0 0 8px;}
.order-item .order-description .order-price strong{color: var(--blackBg);}
.order-item .order-description .order-details{margin-bottom: 10px;}
.order-item .order-description .order-details p{font-style: italic;font-size: 12px;line-height: 12px;}

.flash-message{position: fixed;right: 0;bottom: 30px;z-index: 99999;width: 300px;max-width: 90%;}
.flash-message h6{margin: 5px 0 !important;}

/* - */
.order-type{padding: 0 25px 15px;margin-bottom: 15px;border-bottom: 1px solid #eee;}
.order-type p{cursor: pointer;margin: 0;}
.order-type p span{color: var(--red);}
.order-type p img{height: 20px;width: auto;}

/* - */
.order-type-popup h3{text-align: center;font-weight: normal;text-transform: uppercase;color: var(--blackBg);line-height: 1.2;margin-bottom: 40px;}
.order-type-popup button{border: 0;padding: 10px 25px;text-transform: uppercase;border: 2px solid var(--danger);background-color: var(--danger);color: #fff;}
.order-type-popup button.active, .order-type-popup button:hover{text-transform: uppercase;background-color: transparent;color: var(--danger);}

/* - */
.contact-dailer{position: fixed;right: 20px;bottom: 20px;z-index: 99;}
.contact-dailer img{width: 65px;width: 65px;}

/* - */
nav{position: fixed;top: 55px;right: -310px;width: 300px;height: calc(100vh - 130px);background-color: #fff;padding: 40px 0 30px;}
nav.active{right: 0;}
nav .close-menu-mobile{width: 18px;height: 18px;position: absolute;left: 12px;top: 12px;cursor: pointer;}
nav .container{display: flex;flex-direction: column;height: 100%;justify-content: space-between;padding: 0 20px;}
nav .menu a{color: var(--gray);display: block;padding: 10px 0;margin: 0;border-bottom: 1px solid #ddd;}
nav .social-media{overflow: hidden;}
nav .social-media p{display: none;}
nav .social-media a{display: inline-block;}
nav .social-media img{margin-left: 0;margin-right: 18px;}
nav p{margin: 20px 0 0;}
nav .button{margin: 0 0 15px;display: block;}
nav .button a{padding: 10px 25px 10px;font-weight: 300;display: inline-block;}

/* - */
.header-menu{height: 65px;width: 100%;background-color: #000;}
.header-menu .logo{position: relative;overflow: visible;}
.header-menu .logo img{width: 70px;height: 70px;position: relative;top: 15px;}
.header-menu .open-cart{position: relative;}
.header-menu .open-cart img{width: 24px;margin-top: 5px;}
.header-menu .open-cart span{background-color: var(--red);color: #fff;width: 20px;position: absolute;top: -5px;right: -10px;height: 20px;display: flex;align-items: center;justify-content: center;font-size: 12px;border-radius: 50%;}

/* - */
.cart-mobile{position: fixed;top: 0;right: -310px;background-color: #fff;z-index: 9999;height: 100vh;overflow-y: auto;width: 300px;padding: 40px 0 30px;}
.cart-mobile.active{right: 0;}
.cart-mobile .close-cart-mobile{width: 18px;height: 18px;position: absolute;left: 12px;top: 12px;cursor: pointer;}

/* - */
.mobile-update-qty{display: flex;align-items: center;justify-content: space-between;margin-left: 7px;line-height: 15px;}
.mobile-update-qty span{width: 17px;height: 17px;display: block;background-color: var(--green);border-radius: 50%;text-align: center;color: #fff;}
.mobile-update-qty i{margin: 0 5px;font-size: 14px;}

/* - */
.form-sign .css-nakgy8-TimeKeeper{width: 100%;box-shadow: none;}

.go-back img{height: 25px;}

.Loading-add-cart{position: relative;width: 30px;height: 30px;background-color: var(--red);border-radius: 50%;}
.Loading-add-cart:after{content: '';position: absolute;border-radius: 50%;top: 50%;left: 50%;border: 0px solid white;-webkit-transform: translate(-50%, -50%);transform: translate(-50%, -50%);-webkit-animation: loading 1000ms ease-out forwards infinite;animation: loading 1000ms ease-out forwards infinite;}

.payment{padding: 15px 25px;border-top: 1px solid #eee;overflow: hidden;}
.payment ul{padding: 0;margin: 0;list-style-type: none;}
.payment li{margin-bottom: 10px;padding-left: 22px;position: relative;cursor: pointer;}
.payment li:last-child{margin: 0;}
.payment li::before{content: "";width: 15px;height: 15px;position: absolute;top: 45%;left: 0;border: 1px solid #ddd;border-radius: 50%;transform: translateY(-50%);-moz-transform: translateY(-50%);-webkit-transform: translateY(-50%);}
.payment li.active::before{background-color: var(--red);border-color: var(--red);}

@-webkit-keyframes loading {
    0% {border: 0px solid white;}
    20% {border: 8px solid white;width: 0%;height: 0%;}
    100% {border: 8px solid white;width: 100%;height: 100%;}
}
@keyframes loading {
    0% {border: 0px solid white;}
    20% {border: 8px solid white;width: 0%;height: 0%;}
    100% {border: 8px solid white;width: 100%;height: 100%;}
}

.animation-cart{width: 100%;height: 100%;position: absolute;background-color: rgba(255,255,255,.7);display: flex;align-items: center;justify-content: center;z-index: 12;}


.loaderCircle{display:inline-block;width:20px;height:20px;border:2px solid transparent;background:0;border-radius:999px;border-top-color:var(--red);border-left-color:var(--red);animation: spin 0.7s infinite linear;}
.total .loaderCircle{position: absolute;left: 10px;top: 17.5px;}
@keyframes spin {
    from {transform:rotate(0deg);-moz-transform:rotate(0deg);-webkit-transform:rotate(0deg);} 
    to {transform:rotate(360deg);-moz-transform:rotate(360deg);-webkit-transform:rotate(360deg);} 
}
@-webkit-keyframes spin {
    from {transform:rotate(0deg);-moz-transform:rotate(0deg);-webkit-transform:rotate(0deg);} 
    to {transform:rotate(360deg);-moz-transform:rotate(360deg);-webkit-transform:rotate(360deg);} 
}

.simple-page h1{color: var(--blackText);}
.simple-page h2{color: var(--greenTransparent);font-size: 20px;}
.simple-page p a{color: var(--red);}
.simple-page p a:hover{text-decoration: underline;}